import React, { useEffect, useRef, memo } from 'react';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-youtube/dist/Youtube.js';
import { isEqual } from 'lodash';

import usePrevious from '../hooks/use-previous';

export type VideoPlayerProps = {
  videoJsOptions: VideoJsPlayerOptions;
  onPlayerReady?: (player: VideoJsPlayer) => void;
  onError?: (error: any) => void;
};

const VideoPlayer = memo(function VideoPlayer({ videoJsOptions, onPlayerReady, onError }: VideoPlayerProps) {
  const videoNodeRef = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<VideoJsPlayer>();
  const prevVideoJsOptions = usePrevious(videoJsOptions);

  const failed = (e: any) => {
    if (onError) {
      onError(e);
    }
  };

  useEffect(() => {
    if (isEqual(prevVideoJsOptions, videoJsOptions)) {
      return;
    }

    // if the videojs option is updated, re-instantiate player with the new options
    // videojs will clean up DOM event listener upon dispose
    if (playerRef.current) {
      playerRef.current.dispose();
    }

    videojs(videoNodeRef.current as any, videoJsOptions).ready(function () {
      playerRef.current = this;
      if (onPlayerReady) {
        onPlayerReady(this);
      }
    });
  }, [onPlayerReady, playerRef, prevVideoJsOptions, videoJsOptions]);

  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
      }
    };
  }, []);

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  return (
    <div className="c-player">
      <div key={JSON.stringify(videoJsOptions)} className="c-player__screen" data-vjs-player="true">
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video ref={videoNodeRef} className="video-js vjs-big-play-centered" onError={failed} />
      </div>
    </div>
  );
});
export default VideoPlayer;
